<template>

  <div
    id="order-items"
    class="bg-white px-1 rounded-lg"
  >
    <h2 class="font-weight-bolder font-medium-5 pt-2">
      {{ $t('Order Items') }}
    </h2>
    <scan
      :asset-item-serial-numbers="assetItemSerialNumbers"
      @getValue="handleCheckScan"
    />
    <h2 class="font-weight-bolder font-medium-5 pt-2">
      <!--      {{ getCategoryForTitle() }}-->
    </h2>
    <l-table-list-collector
      ref="lTableRef"
      :table-columns="tableColumns"
      :module-name="MODULE_NAME"
      :fetched-data="assetsItem"
      :is-searchable.camel="false"
      :is-fullfillment="true"
      :row-active="{
        filedKey: 'pick_at',
        classes: 'highlightRow'
      }"
    >
      <template #head(action)>
        <div
          class="d-flex p-0"
        >
          <b-form-checkbox
            :indeterminate="isIndeterminate && !isCheckedAllAssetItems"
            :checked="isCheckedAllAssetItems"
            @change="handleCheckAllItems"
          />
        </div>
      </template>
      <template #cell(action)="{ data: { item }}">
        <div
          class="d-flex pl-1"
        >
          <b-form-checkbox
            :checked="item.checked"
            @change="item.checked = !item.checked"
          />
        </div>
      </template>
      <template #cell(state)="{ data: { item }}">
        <div
          class="d-flex pl-1"
        >
          <state-of-asset
            :data="item"
            :with-label="false"
          />
        </div>
      </template>
      <template #cell(sku)="{ data: { item }}">
        {{ getValueFromGivenObjectByKey(item, 'product.sku') }}
      </template>
      <template #cell(name)="{ data: { item }}">
        {{ getValueFromGivenObjectByKey(item, 'product.name') }}
      </template>
      <template #cell(location)="{ data: { item }}">
        {{ getValueFromGivenObjectByKey(item, 'location') }}
      </template>
      <template #cell(asset_id)="{ data: { item }}">
        <div>
          <sub-rental-assets v-if="item.asset_id" />
          <span v-else>
            {{ getValueFromGivenObjectByKey(item, 'asset_id') }}
          </span>
        </div>
      </template>
      <template #cell(pick_at)="{ data: { item }}">
        <div
          class="pl-1"
        >
          <b-form-checkbox
            :checked="Boolean(item.pick_at)"
            @change="item.pick_at = item.pick_at ? null : new Date().toString()"
          />
        </div>
      </template>
      <template #cell(inspected_outbound)="{ data: { item }}">
        <div
          class="pl-1"
        >
          <b-form-checkbox
            :disabled="!item.pick_at"
            :checked="!!item.inspected_outbound"
            @change="item.inspected_outbound = item.inspected_outbound ? null : true"
          />
        </div>
      </template>
    </l-table-list-collector>
  </div>
</template>

<script>
import LTableListCollector from '@/views/components/LTableListCollector.vue'
import { BFormCheckbox } from 'bootstrap-vue'
import StateOfAsset from '@/views/main/inventory/inventory-view/components/StateOfAsset.vue'
import { assetStates } from '@/enum/asset-statuses'
import Scan from '@/views/components/Scan/Scan.vue'
import { find, map } from 'lodash'
import { getValueFromGivenObjectByKey } from '@core/utils/utils'
import SubRentalAssets from '@/views/main/warehouse/main-order/components/sub-rental-asset/SubRentalAsset.vue'
import config from '../config'

export default {
  name: 'RentalSalesOrderItems',
  components: {
    Scan, StateOfAsset, BFormCheckbox, LTableListCollector, SubRentalAssets,
  },
  computed: {
    orderItem() {
      return this.$store.state[this.MODULE_NAME].orderItem
    },
    assetsItem() {
      return this.orderItem.order_items
    },
    isIndeterminate() {
      return this.assetsItem.some(item => item.checked)
    },
    isCheckedAllAssetItems() {
      return this.assetsItem.every(item => item.checked)
    },
    assetItemSerialNumbers() {
      return map(this.assetsItem, item => `${item?.inventory_item?.serial_number}_${item?.id}`)
    },
  },
  methods: {
    handleCheckAllItems() {
      this.orderItem.order_items = this.assetsItem.map(i => ({
        ...i,
        checked: !i.checked,
        pick_at: i.checked ? null : i.pick_at,
        inspected_outbound: i.checked ? null : i.inspected_outbound,
      }))
    },
    handleCheckScan(value) {
      const val = value.toString().trim()
      const checkedItems = find(this.assetsItem, { inventory_item: { serial_number: val } })
      if (!checkedItems) return
      this.orderItem.order_items = this.assetsItem(i => {
        if (i.id === checkedItems.id) {
          return {
            ...i,
            pick_at: i.pick_at ? null : new Date().toString(),
          }
        }
      })
    },
  },
  setup() {
    const { MODULE_NAME, tableColumns } = config()
    return {
      MODULE_NAME,
      tableColumns,
      assetStates,
      getValueFromGivenObjectByKey,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
